import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dashbot is hosting its fourth annual `}<a parentName="p" {...{
        "href": "/superbot/sb2020"
      }}>{`Superbot Conference`}</a>{` focused on Chatbots, Voice Skills, and AI on March 31st in San Francisco. If you are interested in learning more about the space, this is the conference for you.`}</p>
    <p>{`We have seen quite a bit of change over the past four years. When we first started, Facebook Messenger chatbots were all the rage. Over the years, we have seen interests shift to voice skills, and more recently, to text-based, customer service chatbots relying on Natural Language Processing (NLP).`}</p>
    <p>{`We have an amazing line-up of speakers for Superbot including folks from Amazon Alexa, Google, Facebook, GoDaddy, Intuit, Mercedes Benz, and many more.`}</p>
    <p>{`If you are interested in attending, here are some of the topics we are looking forward to exploring more.`}</p>
    <h2>{`The Future of Voice`}</h2>
    <div className="wp-block-image">
  <figure className="aligncenter">
    ![](https://i.dashbot.io/wp-home/uploads/2020/03/01203552/blog-speakers.png)
  </figure>
    </div>
Voice is one of the hottest areas in conversational interfaces. We see it as the
natural way for people to interact with computers. If you recall all the videos
of two-year olds, swiping on an iPhone or iPad, the same thing happens with
devices like Alexa and Google Home – kids already know how to interact.
    <p>{`Enterprise brands are exploring all sorts of use cases from entertainment and marketing, to knowledge sharing and real functionality. Brands in retail, financial services, health care, CPG, travel, and more are implementing Voice skills.`}</p>
    <p>{`The devices are becoming more prevalent as well. We conducted a `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/11/21/exploring-usage-behavior-of-alexa-and-google-home-voice-assistants/"
      }}>{`survey`}</a>{` showing 58% of users have multiple devices in their home – in the kitchen, living room, bedroom, and more.`}</p>
    <p>{`The devices are not just in the home. We see them in hotel rooms, in the office, and in the car.`}</p>
    <p>{`At Superbot we will hear from leaders like Lisa Falkson of Amazon Alexa, Kat Zdan of Xandra, Brandon Kaplan of Skilled Creative, Michelle Mullenax of the American Red Cross, and Rachel Batish of Audioburst discuss where they see the future of voice.`}</p>
    <p>{`Mihai Antonescu of Mercedes Benz will dive deeper into the use of voice outside the home – specifically in the car. Mercedes MBUX is an impressive voice assistant that can understand complex requests and provide effective responses – all while filtering out ambient noise that might occur in or around the car.`}</p>
    <h2>{`Designing for Conversational Interfaces`}</h2>
    <div className="wp-block-image">
  <figure className="aligncenter">
    ![](https://i.dashbot.io/wp-home/uploads/2020/03/01203550/blog-design.png)
  </figure>
    </div>
Designing for conversational interfaces is hard. It is difficult to know all the
things a user may say or ask, as well as all the ways a user may do so. It is
all unstructured data.
    <p>{`Users will not only tell you what they want, but what they think of the response, which sometimes is not too pleasant.`}</p>
    <p>{`How do you design for this?`}</p>
    <p>{`We have a group of amazing experts presenting on best practices in conversational design.`}</p>
    <p>{`Will Hall, the Chief Creative Officer of RAIN, will share his insights and learnings having worked on voice skills for top brands like Nike, Starbucks, Tide, Black Rock, and more.`}</p>
    <p>{`Jessie Kitchens, a conversational designer with Facebook, will share her insights in conversational design, drawing upon her theater and acting experiences.`}</p>
    <p>{`Casey Phillips, the Senior Product Manager of Conversational AI at Intuit, will discuss how to develop effective solutions to handle fallback cases.`}</p>
    <p>{`Kimberley Harvey, a conversational designer at Google, will share engaging methods for increasing user acquisition and discovery.`}</p>
    <p>{`For those interested in NLP and AI, Alex Weidauer, the CEO of RASA, will explore the advancements in state of the art conversational AI.`}</p>
    <h2>{`User Acquisition and Discovery`}</h2>
    <div className="wp-block-image">
  <figure className="aligncenter">
    ![](https://i.dashbot.io/wp-home/uploads/2020/03/01203548/blog-acquisition.png)
  </figure>
    </div>
One of the biggest challenges in the conversational interface space is user
acquisition and discovery.
    <p>{`For the past three years, we `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/11/21/exploring-usage-behavior-of-alexa-and-google-home-voice-assistants/"
      }}>{`surveyed owners of Alexa and Google Home`}</a>{` devices on how many third-party voice apps they use, and how they heard of them. This year, only 13.5% of respondents use their devices for third party skills. More than 54% only have one to three third-party skills and 21% do not have any at all.`}</p>
    <p>{`With any new platform, user acquisition, discovery, and monetization are all early challenges.`}</p>
    <p>{`Our panel, including Ananya Sharan of Pandora, Spencer Audet of Ubisoft, and Stacey King of Amazon .bot, and moderated by Kirk Owen of Voice Intelligence, will explore effective ways to improve acquisition and discovery.`}</p>
    <h2>{`Multi-channel Brand Strategies`}</h2>
    <div className="wp-block-image">
  <figure className="aligncenter">
    ![](https://i.dashbot.io/wp-home/uploads/2020/03/01203549/blog-channels.png)
  </figure>
    </div>
As the space continues to mature, enterprise brands are expanding their
offerings across channels – voice, text, IVR, and more. For example, there is a
very popular pizza delivery company that enables ordering through seven
different conversational interfaces.
    <p>{`We will hear from Joe Bond of Legal Shield, Tim Carlson of Travelers, Trishala Pilai of MyPlanet, and Joti Balani of FreshRiver on how their companies, or clients, are leveraging multiple conversational channels.`}</p>
    <h2>{`The Rise of Customer Service Chatbots`}</h2>
    <div className="wp-block-image">
  <figure className="aligncenter">
    ![](https://i.dashbot.io/wp-home/uploads/2020/03/01203550/blog-customer-service.png)
  </figure>
    </div>
An area where we have seen significant increased interest and growth is in
customer service chatbots.
    <p>{`Not only are enterprises looking to reduce costs and provide 24/7 support, they believe the chatbot has the potential for a better user experience. In a `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/04/24/customer-service-chatbots-providing-an-effective-solution/"
      }}>{`survey`}</a>{` we conducted of enterprises looking to build a customer service chatbot, 59% felt a chatbot would improve customer service and another 57% felt a chatbot would be a better user experience.`}</p>
    <p>{`Anju Sharma of HP, Mira Lynn of GoDaddy, and Jide Adebayo of Conversocial will discuss best practices in customer service chatbots.`}</p>
    <h2>{`Chatbots in the Financial Industry`}</h2>
    <div className="wp-block-image">
  <figure className="aligncenter">
    ![](https://i.dashbot.io/wp-home/uploads/2020/03/01203551/blog-finance.png)
  </figure>
    </div>
The financial services industry has been one of the biggest adopters of
conversational interfaces. We see plenty of popular voice and text based chat
experiences from personal banking institutions.
    <p>{`The experiences are some of the best examples in designing for conversational interfaces including providing effective responses, handling fallbacks, including personality, and more.`}</p>
    <p>{`We will hear from Angela Novosel of TD Ameritrade, Jake Tyler of Finn.ai, and Neel Lilani of Orrick as they discuss chatbots in financial services.`}</p>
    <h2>{`Investor Insights`}</h2>
    <p>{`The conversational interface space has come a long way in the past four years. Advancements in NLP, access to third-party developer tools, and an increase in overall awareness and experience in the space has helped move the space forward significantly.`}</p>
    <p>{`Our panel of investors including Joanne Chen of Foundation Capital, Michael Fanfant of Runa Capital, Ryan Mendoza of Scrum Ventures, and Amanda Galton of Orrick will dive deeper into what investors are looking for and why they are interested in this space.`}</p>
    <h2>{`Socks!`}</h2>
    <p>{`Last, but not least, another great reason to attend Superbot is for this year’s socks!`}</p>
    <p>{`Each year, we have a new design and this year might be the best.`}</p>
    <p>{`Supplies are limited, sign up to attend, and show up early to get a pair!`}</p>
    <h2><div className="wp-block-image"><figure className="aligncenter"><img parentName="h2" {...{
            "src": "https://i.dashbot.io/wp-home/uploads/2020/03/01204020/superbot-socks-2020-500.jpeg",
            "alt": null
          }}></img></figure></div>{`Register to Attend`}</h2>
    <p>{`Superbot is on March 31st in San Francisco.`}</p>
    <p><a parentName="p" {...{
        "href": "/superbot/sb2020"
      }}>{`Sign up today`}</a>{` to ensure your seat and the opportunity to meet with, share with, and learn from leaders in the conversational interface space`}</p>
    <div className="wp-block-button aligncenter is-style-default">
  [Register for Superbot](/superbot/sb2020)
    </div>
### About Dashbot
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is an analytics platform for conversational interfaces that enables enterprises to increase engagement, satisfaction, and conversions through actionable insights and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we provide conversation specific analytics including NLP response effectiveness, sentiment analysis, conversation flows, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our NLP Optimization tools, Phrase Clustering, NLP Assessments, and Live Person Take Over of chat sessions.`}</p>
    <p>{`We support Alexa, Google Home, Web Chat, SMS, Facebook Messenger, Slack, Twitter, Bixby, and any other conversational interface.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      